<template>
    <b-card no-body class="p-1" ref="userAccounts">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 m-1 d-flex justify-content-between align-items-center"
                 dir="rtl"
            >
                <h3 v-if="$route.name === 'show-accounts'">
                    لیست زیر مجموعه ها
                </h3>
                <template v-else>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                        <feather-icon icon="FilterIcon"/>
                        فیلتر
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" @click="downloadExcel">
                        <feather-icon icon="FilterIcon"/>
                        خروجی اکسل
                    </b-button>
                </template>
            </div>

            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <b-modal
                    ref="msg-modal"
                    cancel-variant="outline-secondary"
                    ok-title="ارسال پیام"
                    cancel-title="لغو"
                    centered
                    title="پیام جدید"
                    @ok.prevent="sendMessage"
                    v-model="messageModal"
                    size="lg"
            >
                <b-overlay :show="loading">
                    <b-form>
                        <b-form-group label="عنوان پیام">
                            <b-form-input
                                    type="text"
                                    placeholder="عنوان"
                                    v-model="message.title"
                            />
                        </b-form-group>
                        <b-form-group label="متن پیام">
                            <b-form-textarea placeholder="متن پیام" v-model="message.body"/>
                        </b-form-group>
                    </b-form>
                </b-overlay>
            </b-modal>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        style="white-space: nowrap; min-height : 235px"
                        @sort-changed="sort($event)"
                        no-local-sort

                >
                    <template #cell(name)="data">
                        <span dir="ltr" :class="{'text-success' : data.item.name}">
                            {{ data.item.profile ? data.item.profile.name : '' }}
                        </span>
                    </template>

                    <template #cell(email)="data">
                        <span dir="ltr" :class="{'text-success' : data.item.email}">
                            {{ data.item.email }}
                        </span>
                    </template>

                    <template #cell(mobile)="data">
                        <span dir="ltr">
                            {{ data.item.profile ? data.item.profile.mobile : "" }}
                        </span>
                    </template>

                    <template #cell(created_at)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.created_at) }}
                        </span>
                    </template>

                    <template #cell(status)="data">
                        <b-badge
                                pill
                                :variant="'light-'+authVariant(data.item.status)"
                                class="text-capitalize"
                        >
                            {{ authLabel(data.item.status) }}
                        </b-badge>
                    </template>

                    <template #cell(accounts)="data">
                        <b-badge
                                pill
                                variant="light-info"
                                class="text-capitalize"
                        >
                            {{ data.item.accounts.length }}
                        </b-badge>
                    </template>

                    <template #cell(is_banned)="data">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(!data.item.is_banned)"
                                class="text-capitalize"
                                :badge="12"
                        >
                            {{ statusLabel(!data.item.is_banned) }}
                        </b-badge>
                    </template>

                    <template #cell(orders)="data">
                        <template
                                v-if="$havePermission('LIST_WITHDRAW') || $havePermission('LIST_HISTORIES')"
                        >
                            <feather-icon
                                    v-if="$havePermission('LIST_WITHDRAW')"
                                    icon="ShareIcon"
                                    size="20"
                                    class="text-danger cursor-pointer"
                                    :badge="data.item.orders"
                                    badge-classes="badge-danger cursor-pointer"
                                    v-tooltip="'درخواست های برداشت'"
                                    @click="activeOrdersPage(data.item.email)"
                            />
                            <feather-icon
                                    v-if="$havePermission('LIST_HISTORIES')"
                                    icon="ClockIcon"
                                    size="20"
                                    class="text-warning ml-2 cursor-pointer"
                                    v-tooltip="'تاریخچه'"
                                    @click="orderHistoryPage(data.item.email)"
                            />
                        </template>
                        <not-allowed v-else/>
                    </template>

                    <template #cell(action)="{item,index}">
                        <template
                                v-if="$havePermission('DETAILS_USERS') ||
                                 $havePermission('UPDATE_USERS') ||
                                 $havePermission('LIST_ORDERS') ||
                                 $havePermission('LIST_TRADES') ||
                                 $havePermission('LIST_MESSAGES')"
                        >
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>

                                <b-dropdown-item v-if="$havePermission('DETAILS_USERS')"
                                                 :to="{ name: 'show-accounts', params: { id: item.id } }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">جزییات</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('UPDATE_USERS')" @click="changeStatus(index)">
                                    <feather-icon icon="RefreshCwIcon"/>
                                    <span class="align-middle ml-50">تغییر وضعیت</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('CREATE_NOTIFICATIONS')"
                                                 @click="openMessageModal(item.id)">
                                    <feather-icon icon="MessageSquareIcon"
                                                  badge-classes="badge-info test-badge"/>
                                    <span class="align-middle ml-50">ارسال پیام</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('LIST_ORDERS')"
                                                 :to="{ name: 'Orders', query: { email: item.email } }">
                                    <feather-icon icon="ShoppingBagIcon"/>
                                    <span class="align-middle ml-50">سفارشات</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('LIST_TRADES')"
                                                 :to="{ name: 'Deals', query: { email: item.email } }">
                                    <feather-icon icon="BriefcaseIcon"/>
                                    <span class="align-middle ml-50">معاملات</span>
                                </b-dropdown-item>

                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                    <label for="perpage">تعداد در صفحه</label>
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BPagination,
    BCard,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
import NotAllowed from "@/layouts/components/NotAllowed";

export default {
    name: 'Accounts',
    components: {
        NotAllowed,
        SearchAndFilter,
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        vSelect,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BOverlay
    },
    data: () => ({
        test: 0,
        pageLength: 3,
        dir: false,
        searchTerm: '',
        currentPage: 1,
        perPage: 10,
        rows: 200,
        userData: [],
        isActive: false,
        messageModal: false,
        perPageOptions: [5, 10, 20, 50, 100],
        items: [],
        loading: false,
        loading2: false,
        message: {
            id: '',
            title: '',
            body: ''
        },
        columns: [
            {
                label: 'نام',
                key: 'name',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'ایمیل',
                key: 'email',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'تلفن همراه',
                key: 'mobile',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'تاریخ ساخت اکانت',
                key: 'created_at',
                sortable: false,
                searchType: 'date'
            },
            {
                label: 'احراز هویت',
                key: 'status',
                sortable: false,
                searchType: 'select',
                selectOptions: [
                    {value: 'PRIMARY_AUTH_DONE', label: 'ثبت نام انجام شده'},
                    {value: 'SECONDARY_AUTH_DONE', label: 'پروفایل کامل شده'},
                    {value: 'OTP_DONE', label: 'فینوتک انجام شده'},
                    {value: 'CONFIRMED_IDENTITY', label: 'هویت تایید شده'},
                    {value: 'PENDING', label: 'رمز عبور ست نشده'},
                    {value: 'ACCEPTED', label: 'تایید شده'},
                    {value: 'CONFLICT', label: 'عدم انطباق'},
                    {value: 'REJECTED', label: 'تایید نشده'},
                    {value: 'RESEND_OTP', label: 'نیازمند فینوتک مجدد'},
                ]
            },
            {
                label: 'حساب های تایید شده',
                key: 'accounts',
                // sortable: true,
                // searchType: 'number'
            },
            {
                label: 'وضعیت',
                key: 'is_banned',
                sortable: true,
                searchType: 'select',
                selectOptions: [
                    {label: 'غیر فعال', value: 0},
                    {label: 'فعال', value: 1}
                ]
            },
            {
                label: 'درخواست ها',
                key: 'orders'
            },
            {
                label: 'مدیریت',
                key: 'action'
            },
        ],
    }),
    computed: {
        authVariant() {
            const a = {
                PRIMARY_AUTH_DONE: 'secondary',
                SECONDARY_AUTH_DONE: 'secondary',
                OTP_DONE: 'info',
                CONFIRMED_IDENTITY: 'info',
                PENDING: 'warning',
                ACCEPTED: 'success',
                CONFLICT: 'danger',
                REJECTED: 'danger'
            }
            return e => a[e];
        },
        authLabel() {
            const a = {
                PRIMARY_AUTH_DONE: 'ثبت نام انجام شده',
                SECONDARY_AUTH_DONE: 'پروفایل کامل شده',
                OTP_DONE: 'فینوتک انجام شده',
                CONFIRMED_IDENTITY: 'هوبت تایید شده',
                PENDING: 'در انتظار تایید',
                ACCEPTED: 'تایید شده',
                CONFLICT: 'عدم انطباق',
                REJECTED: 'تایید نشده',
            }
            return e => a[e];
        },
        statusVariant() {
            return e => e ? 'success' : 'danger';
        },
        statusLabel() {
            return e => e ? 'فعال' : 'غیر فعال';
        },
        // georgianToJallali(){
        //     return e => this.$jmoment(e, 'YYYY-MM-DDTHH:mm:ss').format('dddd jD jMMMM jYYYY')
        // }
    },
    methods: {
        activeOrdersPage(id) {
            this.push('withdraw-orders', id)
        },
        orderHistoryPage(id) {
            this.push('wallet-transactions', id)
        },
        push(to, id) {
            this.$router.push({name: to, query: {email: id}})
        },
        openMessageModal(e) {
            this.message.id = e
            this.messageModal = true
        },
        async sendMessage() {
            if (!this.loading) {
                this.loading = true
                try {
                    await this.$axios.post('/users/' + this.message.id + '/notification', this.message)
                    this.loading = false
                    this.message = {
                        id: '',
                        title: '',
                        body: '',
                    }
                    this.messageModal = false
                    this.$error('پیام ارسال شد', '', 'success')
                } catch (e) {
                    this.loading = false
                }

            }
        },

        changeStatus(e) {
            this.$swal({
                title: 'آیا از تغییر وضعیت کاربر مطمئن هستید؟',
                // text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'بله',
                cancelButtonText: 'خیر',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    console.warn('change active')
                    this.state.loading = true
                    const address = '/users/' + this.items[e].id + '/' + (this.items[e].is_banned ? 'un' : '') + 'ban'
                    const res = await this.$axios.post(address)
                    !res.data.message.includes('success')
                    await this.getData(this.currentPage, this.perPage)

                    this.$swal({
                        icon: 'success',
                        title: this.items[e].is_banned ? 'کاربر فعال شد' : 'کاربر غیر فعال شد',
                        confirmButtonText: 'تایید',
                        // text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },

        sort(e) {

            let sort = this.$toSnakeCase(e.sortBy)
            // let sort = e.sortBy
            let sorting = e.sortDesc ? 'DESC' : 'ASC'

            this.$router.push({
                query: {
                    ...this.$route.query,
                    orderBy: sort,
                    sorting: sorting
                }
            })

            this.getData(1, this.perPage)

        },

        async getData(page, perPage) {
            this.state.loading = true

            let address = '/users'
            let queryParams = {
                size: perPage,
                page: page,
                ...this.$route.query,
            }

            if (!this.$route.query['orderBy']) {
                queryParams['orderBy'] = 'created_at'
                queryParams['orderByType'] = 'desc'
            }

            if (this.$route.name === 'show-accounts') {
                address = '/users/' + this.$route.params.id + '/referring-users'
            }

            const res = await this.$axios(
                address,
                {
                    params: queryParams
                }
            )
            this.state.loading = false
            this.items = res.data.data
            this.currentPage = res.data.meta.current_page
            this.rows = res.data.meta.total
        },

        async downloadExcel() {
            await this.$axios(
                {
                    url: '/users',
                    method: 'GET',
                    responseType: 'blob', // important
                    params: {
                        export : 'excel',
                        ...this.$route.query
                    }
                }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.$jmoment().format(this.$dateFormat['faDateTime']) + '.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        }

    },
    mounted() {
        this.getData(1, this.perPage)
    },

}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
