<template>
    <b-card title="شماره حساب های وارد شده">
        <b-form>
            <b-row
                    v-for="(bankAccount,index) in bankAccounts"
                    :key="index"
                    class="pt-1 mb-1 bankAccount mx-0"
                    :class="`border-${statusColor(bankAccount.status)}`">

                <b-col
                        cols="12"
                        md="4"
                >
                    <b-form-group
                            label="شماره شبا : "
                            label-for="roleName"
                            label-cols="4"
                    >
                      <table-field-base :item="bankAccount"
                                        key-name="sheba"
                                        tooltip="اصلاح مقدار"
                                        :address="'/accounts/' + bankAccount.id"
                                        method="patch"
                      />
                    </b-form-group>
                </b-col>

                <b-col
                        cols="12"
                        md="4"
                >
                    <b-form-group
                            label="شماره کارت : "
                            label-for="en-name"
                            label-cols="4"
                    >
                      <table-field-base :item="bankAccount"
                                        key-name="card_number"
                                        tooltip="اصلاح مقدار"
                                        :address="'/accounts/' + bankAccount.id"
                                        method="patch"
                      />
                    </b-form-group>
                </b-col>

                <b-col
                        cols="9"
                        md="2"
                >
                    <b-form-group
                            label="نام بانک : "
                            label-for="en-name"
                            label-cols="6"
                    >
                        <b-form-input
                                id="en-name1"
                                :value="bankAccount.bank"
                                class="text-right"
                                plaintext
                        />
                    </b-form-group>
                </b-col>

                <b-col
                        cols="3"
                        md="2"
                        class="text-right"
                >
                    <b-button
                            v-if="['ACCEPTED', 'CONFIRMED_IDENTITY'].includes(auth)"
                            class="btn-icon"
                            :variant="`flat-${statusColor(bankAccount.status)}`"
                            v-tooltip.top="{ variant: statusColor(bankAccount.status) , title: statusTitle(bankAccount.status)}"
                            @click.prevent="checkAccount(bankAccount)">
                        <feather-icon v-tooltip.bottom="{title:'بررسی مالکیت حساب',delay:{ show: 500, hide: 100 }}" :class="{'rotate' : check[index]}" icon="RefreshCwIcon"/>
                    </b-button>
                    <not-allowed v-else :tooltip="{variant: 'warning',title: 'کاربر اهراز هویت نشده'}"/>
                </b-col>

            </b-row>
        </b-form>
    </b-card>
</template>

<script>
    import {
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BButton,
        // BBadge,
    } from "bootstrap-vue"
    import NotAllowed from "@/layouts/components/NotAllowed";
    import TableFieldBase from "@/Components/TableFieldBase";

    export default {
        name: "BankAccounts",
        components: {
            NotAllowed,
            BCard,
            BForm,
            BFormGroup,
            BFormInput,
            BRow,
            BCol,
            BButton,
          TableFieldBase
            // BBadge,
        },
        props:['bankAccounts','check','auth'],
        data(){
            return{
            }
        },
        watch:{
        },
        computed:{
            statusColor(){
                const a = {
                    REJECTED : 'danger',
                    PENDING : 'warning',
                    ACCEPTED : 'success',
                    CONFLICT : 'danger',
                    USER_CONFLICT : 'danger'
                }
                return e => a[e]
            },
            statusTitle(){
                const a = {
                    REJECTED : 'رد شده',
                    PENDING : 'در انتظار بررسی',
                    ACCEPTED : 'تایید شده',
                    CONFLICT : 'عدم تطابق',
                    USER_CONFLICT : 'عدم تطابق اطلاعات هویتی'
                }
                return e => a[e]
            },
        },
        methods:{
            async checkAccount(e){
                this.$emit('checkAccount',e)
            }
        }
    }
</script>

<style scoped>

    .bankAccount{
        border-radius: 0.428rem;
    }
    .rotate{
        animation: rotate 1s infinite;
    }
    @keyframes rotate {
        from{transform: rotate(0)}
        to{transform: rotate(360deg)}
    }

</style>
